<template>
  <section>
    <div class="blog_cards" v-if="listBlog.length">
      <article class="card" v-for="content in listBlog">
        <router-link v-if="content.url" :to="{name: 'Post', params: {url: content.url}}" class="card_link">
          <div class="card_img">
            <img v-lazy="content.ecommerceimage_set[0].url" :srcset="content.ecommerceimage_set[0].srcset"
                 :sizes="$store.state.sizes" :alt="content.ecommerceimage_set[0].name">
            <span class="card_tag" v-if="content.category">{{ content.category }}</span>
          </div>
          <div class="card_content">
            <h2 class="card_title">{{ content.name }}</h2>
            <p class="card_date">{{ content.create_date }}</p>
            <div class="card_read-more">
              <button aria-label="see-more" type="button" class="button blue full">voir plus</button>
            </div>
          </div>
        </router-link>
        <a aria-label="Retour" v-else class="card_link" disabled>
          <div class="card_img">
            <img v-lazy="content.ecommerceimage_set[0].url" :srcset="content.ecommerceimage_set[0].srcset"
                 :sizes="$store.state.sizes" :alt="content.ecommerceimage_set[0].name">
            <span class="card_tag" v-if="content.category">{{ content.category }}</span>
          </div>
          <div class="card_content">
            <h2 class="card_title">{{ content.name }}</h2>
            <p class="card_date">{{ content.create_date }}</p>
            <div class="card_read-more">
              <button aria-label="see-more" type="button" class="button blue full">voir plus</button>
            </div>
          </div>
        </a>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "BlogCard",
  props: {
    listBlog: {
      required: true,
    },
  },
  methods: {
    replace: function (event, str) {
      event.target.src = str.replace('https://odoo.lafamilleboutrais.group', 'http://51.178.140.145:8090')
    },
  }
}
</script>

<style lang="scss" scoped>
section {
  width: 100%;
}

.blog_cards {
  @include flex(space-between, flex-start, row, wrap, $gap: 1rem .76rem);
  align-items: stretch;

  @media screen and (min-width: 992px) {
    justify-content: center;
    gap: 2vw;
  }

  .card {
    @include flex(flex-start, flex-start, column);
    color: #8BACD9;
    border-radius: 16px;
    border: solid 1px $Blue;
    overflow: hidden;
    box-shadow: 0 5px 15px -10px rgba(0, 0, 0, 1);
    flex: 0 0 calc(50% - .38rem);
    transition: all .2s;

    &:hover {
      transition: translate 1s, box-shadow .75s;
      translate: 0 -5px;
      box-shadow: 0 7.5px 15px -7.5px rgba(0, 0, 0, 1);

      .card_title {
        transition: .2s ease;
        color: $Blue;
      }

      .card_product_img img {
        transition: 1s ease;
        scale: 1.1;
      }

      .card_read-more .button {
        background-color: $White;
        color: $Blue;
        border-color: $Blue;
      }

    }

    @media screen and (max-width: 350px) {
      max-width: 100%;
      flex: 0 0 100%;
    }

    @media screen and (min-width: 576px) {
      max-width: calc(50% - 1vw);
    }

    @media screen and (min-width: 768px) {
      max-width: calc(33% - 1.5vw);
    }

    @media screen and (min-width: 1200px) {
      max-width: calc(25% - calc(6vw / 4));
    }

    &_link {
      @include flex(flex-start, flex-start, column);
      width: 100%;
      height: 100%;
      text-decoration: none;
    }

    &_img {
      overflow: hidden;
      width: 100%;
      flex: 0 0 66%;
      max-height: 200px;
      height: 200px;
      position: relative;
      @media screen and (min-width: 576px) {
        max-height: 200px;
      }

      img {
        object-fit: cover;
        width: 100%;
        max-height: 200px;
        height: 200px;
        scale: 1;
        will-change: scale;
        transition: .3s;
      }

      .card_tag {
        @include position(20px, 0);
        background-color: rgba(255, 255, 255, .75);
        color: $Blue;
        padding: 2px 15px 2px 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;

        &::before {
          content: '#';
        }
      }

    }

    &_content {
      @include flex(flex-start, center, column, $gap: .5rem);
      padding: .75rem;
      width: 100%;
      flex: 2;
      text-align: center;

      @media screen and (min-width: 576px) {
        padding: 5%;
        gap: .25rem;
      }

      @media screen and (min-width: 768px) {
        padding: 3% 5% 5%;
      }

      .card_title {
        text-transform: uppercase;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: .5px;
        color: $Black;

        @media screen and (min-width: 992px) {
          font-size: 1rem;
        }
      }

      .card_date {
        font-size: 12px;
        font-weight: lighter;
        color: $Grey;

        @media screen and (min-width: 768px) {
          font-size: 14px;
        }
      }

      .card_read-more .button {
        background-color: $Blue;
        font-size: 15px;
      }
    }
  }
}
</style>